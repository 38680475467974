@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
	.logo-primary {
		--logo-hexagon-color: hsl(var(--nextui-primary));
		--logo-n-color: #ffffff;
	}

	.logo-monochrome {
		--logo-hexagon-color: hsl(var(--nextui-foreground));
		--logo-n-color: hsl(var(--nextui-background));
	}
}
